import React from "react";
import GlobalStyles from "styles/GlobalStyles";
import { css } from "styled-components/macro"; //eslint-disable-line

import RestaurantLandingPage from "demos/RestaurantLandingPage";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  return (
    <>
      <title>Nutrito - Soluția completă pentru nutriție | Webrio.ro</title>
      <meta
        name="description"
        content="Nutrito este soluția completă pentru nutriție. Conformă cu cerințele legale pentru declarația nutrițională a produselor alimentare. Înscrie-te acum și obține o reducere de 20% la lansarea aplicației."
      />
      <GlobalStyles />
      <RestaurantLandingPage />
    </>
  );
}
