import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import logo from "../images/logo.svg";
import webrioLogo from "images/webrio.png";

import illustrationImageSrc from "images/register.jpg";

const Container = tw.div`relative flex p-5 lg:p-0`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left py-5`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-gray-700 xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 hover:border-2 hover:bg-white hover:text-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`hidden lg:flex justify-center lg:justify-end items-center`;
const CustomersLogoStrip = styled.div`
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;
function ComingSoon() {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true); // Added state for email validation

  const validateEmail = (email) => {
    // Regular expression for email validation
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return regex.test(email);
  };

  const onSubmit = () => {
    if (validateEmail(email)) {
      // Email is valid, proceed with submission
      setIsValidEmail(true);
      const customHeader =
        "KSZS5iOui@GfHL!S#DrBTw3_XGDbWU9-p!pxkTuKTO_-MSH$3g3w5b_GWh8M-IJr";

      // Request headers
      const headers = new Headers({
        "Content-Type": "application/json",
        Authorization: customHeader,
      });

      fetch(
        "https://sheet.best/api/sheets/8dc75782-302d-48cd-89cf-6f74fa62dfb6",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({ email }),
        }
      )
        .then((response) => {
          if (response.ok) {
            setIsSubmitted(true);
          } else {
            console.error("Error sending data");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      // Email is not valid, show an error
      setIsValidEmail(false);
    }
  };

  return (
    <>
      <Container>
        <IllustrationContainer>
          <img
            tw="min-w-0 w-full max-w-lg xl:max-w-3xl h-screen"
            src={illustrationImageSrc}
            loading="lazy"
            alt="Restaurant Image"
          />
        </IllustrationContainer>

        <LeftColumn>
          <div tw="flex items-center flex-1 content-center justify-center">
            <img src={logo} alt="logo" tw="inline-flex" />
          </div>
          <Heading>
            <span tw="text-primary-500">
              Nutrito este soluția completă pentru nutriție
            </span>
          </Heading>
          <Paragraph>
            Îți oferim o soluție simplă și rapidă pentru a vă conforma
            cerințelor legale în ceea ce privește declarația nutrițională a
            produselor alimentare.
          </Paragraph>
          <Actions>
            <input
              type="email"
              placeholder="Adresa ta de e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={!isValidEmail ? "invalid" : ""} // Apply a CSS class for styling
            />
            <button onClick={onSubmit} disabled={isSubmitted}>
              {isSubmitted ? "Inscris cu succes!" : "Inscriere"}
            </button>
          </Actions>
          {!isValidEmail && (
            <p className="error-text" tw="text-red-500 py-3">
              Introduceți o adresă de e-mail validă
            </p>
          )}
          <Paragraph>
            Vei fi notificat cu privire la lansarea aplicatiei și vei obtine și
            o reducere de 20%.
          </Paragraph>
          <CustomersLogoStrip>
            <p tw="text-xl text-gray-500 font-semibold">Functii:</p>
            <ul tw="mt-4 list-disc list-inside">
              <li tw="text-lg text-gray-700 mb-2">
                Calculează și generează cu precizie și eficiență declarația
                nutrițională conformă cu prevederile Ordinului ANPC 201/2022
                pentru toate rețetele.
              </li>
              <li tw="text-lg text-gray-700 mb-2">
                Bază de date extinsă cu alimente UE in linie cu reglementarile
                alimentare din România și ANPC.
              </li>
              <li tw="text-lg text-gray-700 mb-2">
                Descarcă sau generează cod QR pentru declarația nutrițională
                pentru meniu sau rețetă.
              </li>
              <li tw="text-lg text-gray-700 mb-2">
                Generează ancheta alimentară rapid și ușor.
              </li>
              <li tw="text-lg text-gray-700 mb-2">
                Generează meniuri personalizate pentru antrenori perosonali sau
                nutriționiși.
              </li>
            </ul>
          </CustomersLogoStrip>
          <p tw="mt-16 text-gray-500 justify-start flex">
            Un produs digital webrio.ro
          </p>
          <div tw="flex items-center justify-start ">
            <img src={webrioLogo} alt="logo" tw="flex h-12" />
          </div>
        </LeftColumn>
      </Container>
    </>
  );
}
export default ComingSoon;
